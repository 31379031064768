
const Data = [
    {
        id: 1,
        category: "education",
        icon: "icon-graduation",
        year: "2021 - present",
        title: "Bachelor's Degree",
        desc: "My current Bachelor's degree studies at XAMK as a software engineer."
    },
    {
        id: 2,
        category: "education",
        icon: "icon-graduation",
        year: "2018 - 2019",
        title: "Vocational qualification in immediate supervisor",
        desc: "Studies to further my knowledge and skills as an immediate supervisor."
    },
    {
        id: 3,
        category: "education",
        icon: "icon-graduation",
        year: "2008 - 2011",
        title: "Undergraduate",
        desc: "My undergraduate studies in a high school."
    },
    {
        id: 4,
        category: "experience",
        icon: "icon-graduation",
        year: "",
        title: "C#",
        desc: "Most used language, thanks to multiple school projects and an internship."
    },
    {
        id: 5,
        category: "experience",
        icon: "icon-graduation",
        year: "",
        title: "Database",
        desc: "I'm familiar with multiple databases, for example MySQL, MongoDB and Postgre."
    },
    {
        id: 6,
        category: "experience",
        icon: "icon-graduation",
        year: "",
        title: "Python",
        desc: "I can handle the basics and some of the more advanced stuff. Little bit of experience from machine learning and AI."
    },
    {
        id: 7,
        category: "experience",
        icon: "icon-graduation",
        year: "",
        title: "React",
        desc: "The reason I like doing web apps. Neat and simple."
    },
    {
        id: 8,
        category: "experience",
        icon: "icon-graduation",
        year: "",
        title: "Node.js",
        desc: "Currently refactoring a Node.js back-end for my thesis."
    },
    {
        id: 9,
        category: "experience",
        icon: "icon-graduation",
        year: "",
        title: "GitHub",
        desc: "Not a lot of public stuff here, should try to make more projects which can be shown for public."
    },
    {
        id: 10,
        category: "experience",
        icon: "icon-graduation",
        year: "",
        title: "CI/CD",
        desc: "I've used GitHub Actions for CI/CD."
    },
    
    
]

export default Data