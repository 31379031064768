import './about.css';


const About = () => {
    return (
        <section className="about container section" id='about'>
            <h2 className="section_title">About Me</h2>

            <div className='about_container grid'></div>

                <div className='about_data grid'>
                    <div className='about_info'>
                        <p className='about_desc'>My name is Jere Janhunen, and I'm a last-year software engineering student at South-Eastern Finland 
                        University of Applied Sciences in Mikkeli working on my thesis with Node.js and Unreal Engine 5. 
                        Will be updating these pages as soon as I can find the time from my job hunting and thesis.
                        My whole life I've been intrigued by everything about computers and the Internet,
                        and how it all works. That's why I'm becoming a software engineer; to find some answers for my questions. 
                        While I may not have the most experience in programming just yet, I am learning and growing each passing day. 
                        ----I got the percentages from my head----&gt;</p>
                        <a href="https://drive.google.com/file/d/1h6XI0eijLwySdBXY_jWGq89JpCrV1OQx/view" download="CV_Janhunen" className='btn'>Download CV</a>
                    </div>

                    <div className='about_skills grid'>
                        <div className='skills_data'>
                            <div className='skills_titles'>
                                <h3 className='skills_name'>Development</h3>
                                    <span className='skills_number'>85%</span>
                            </div>
                            <div className='skills_bar'>
                                <span className='skills_percentage development'></span>
                            </div>
                        </div>

                        <div className='skills_data'>
                            <div className='skills_titles'>
                                <h3 className='skills_name'>Problem solving</h3>
                                    <span className='skills_number'>95%</span>
                            </div>
                            <div className='skills_bar'>
                                <span className='skills_percentage problem'></span>
                            </div>
                        </div>

                        <div className='skills_data'>
                            <div className='skills_titles'>
                                <h3 className='skills_name'>Continuous learning</h3>
                                    <span className='skills_number'>95%</span>
                            </div>
                            <div className='skills_bar'>
                                <span className='skills_percentage learning'></span>
                            </div>
                        </div>

                        <div className='skills_data'>
                            <div className='skills_titles'>
                                <h3 className='skills_name'>Python</h3>
                                    <span className='skills_number'>85%</span>
                            </div>
                            <div className='skills_bar'>
                                <span className='skills_percentage python'></span>
                            </div>
                        </div>

                        <div className='skills_data'>
                            <div className='skills_titles'>
                                <h3 className='skills_name'>C#</h3>
                                    <span className='skills_number'>90%</span>
                            </div>
                            <div className='skills_bar'>
                                <span className='skills_percentage sharp'></span>
                            </div>
                        </div>

                        <div className='skills_data'>
                            <div className='skills_titles'>
                                <h3 className='skills_name'>React</h3>
                                    <span className='skills_number'>80%</span>
                            </div>
                            <div className='skills_bar'>
                                <span className='skills_percentage react'></span>
                            </div>
                        </div>

                        <div className='skills_data'>
                            <div className='skills_titles'>
                                <h3 className='skills_name'>Node.js</h3>
                                    <span className='skills_number'>90%</span>
                            </div>
                            <div className='skills_bar'>
                                <span className='skills_percentage html'></span>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    )
}

export default About